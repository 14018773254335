import React from 'react';

import {BrowserRouter as Router, Routes, Route} from "react-router-dom"

import App from "./App";
import DonateQr from "./components/donateQr/DonateQr";
import DonateCred from "./components/donateCred/DonateCred";

function AppMain() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<App/>}/>
                    <Route path="/donateQr" element={<DonateQr/>}/>
                    <Route path="/donateCr" element={<DonateCred/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default AppMain;