import Home from "./components/Home/Home";
import About from "./components/About/About";
// import Information from "./components/information/Information";
import Donate from "./components/Donate/Donate";
// import Cta from "./components/Cta/Cta";
import Navigation from "./components/Navigation/Navigation";
import {Helmet, HelmetProvider} from 'react-helmet-async';


import "./app.css"
import {useTranslation} from "react-i18next";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";


function App() {


    const { t } = useTranslation();


    return (
        <HelmetProvider>
    <div className="App">
        <Helmet>
            <title>{t("title-title")}</title>
            <meta name="description" content={t("about-description-fsrParagraph")} />
        </Helmet>
            <Navigation/>
            <Home/>
            <div id="about">
                <About/>
                {/*<Information/>*/}
                {/*<Cta/>*/}
            </div>
            <Donate/>
            <Contact/>
            <Footer/>
    </div>
        </HelmetProvider>
  );
}

export default App;
