import React, {useEffect, useState} from 'react';
import "./home.css"

import { useTranslation } from "react-i18next";

import map from "./img/possible1.png"
import {Link} from "react-scroll";

function Home() {

    const [width, setWidth] = useState(0)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setWidth])




    const { t } = useTranslation()
    return (
        <div className="home" id="home">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="var(--clr-red)" fillOpacity="1" d="M0,160L24,160C48,160,96,160,144,138.7C192,117,240,75,288,64C336,53,384,75,432,106.7C480,139,528,181,576,208C624,235,672,245,720,240C768,235,816,213,864,186.7C912,160,960,128,1008,133.3C1056,139,1104,181,1152,202.7C1200,224,1248,224,1296,197.3C1344,171,1392,117,1416,90.7L1440,64L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>
            <div className="home-info">
                <div className="home-info-description">
                    {/*<div className="greeting">*/}
                    {/*    <div className="wrapper-heading">*/}
                    {/*        <h1 className="greeting-heading">{t("greeting")}</h1>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="cta">
                        <div className="wrapper-cta">
                            <h2>{t("cta")}</h2>
                        </div>
                    </div>
                    <div className="home-link-btn">
                        <Link className="home-link"  to="donate" spy={true} smooth={true} offset={0} duration={500}>
                            {t("home-link-btn")}
                        </Link>
                    </div>
                </div>
                <div className="home-info-map">
                    <div className="map">
                        <div className="wrapper-map">
                            <img src={map} alt="map"/>
                        </div>
                    </div>
                </div>
            </div>
            {width < 500 &&
                <div className="home-c">
                 <p className="home-c-t">Споконвіку Прометея</p>
                    <p className="home-c-t">Там орел карає,</p>
                    <p className="home-c-t">Що день божий добрі ребра</p>
                    <p className="home-c-t">Й серце розбиває.</p>
                        <p className="home-c-t"> Розбиває, та не вип’є</p>
                            <p className="home-c-t"> Живущої крові —</p>
                                <p className="home-c-t"> Воно знову оживає</p>
                                    <p className="home-c-t"> І сміється знову.</p>
                </div>
                    }
        </div>
    );
}

export default Home;