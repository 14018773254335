import "./styleButton.css"



export default function Button(props){

    const style = {
        borderColor: props.navBar ? "var(--clr-black)" : "white",
    }

    return(
            <button className="hamburger-button" onClick={props.handleChange} style={style}>
                <svg stroke={props.navBar ? "var(--clr-black)" : "white"} fill="none" className={props.attribute ? 'hamburger2' : 'hamburger'} viewBox="-10 -10 120 120"
                     width="35">
                    <path className={props.attribute ? 'line2' : 'line'} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"
                          d="m 20 40 h 60 a 1 1 0 0 1 0 20 h -60 a 1 1 0 0 1 0 -40 h 30 v 70">

                    </path>
                </svg>
            </button>
    )
}