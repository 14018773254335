import React, {useEffect, useState} from 'react';

import "./footer.css"


function Footer() {

    return (
        <div className="footer">
            <p>Всі права захищені © </p>
            <br/>
            <p>БЛАГОДІЙНА ОРГАНІЗАЦІЯ «БЛАГОДІЙНИЙ ФОНД «ГУМАНІТАРНИЙ ШТАБ»</p>
        </div>
    );

}

export default Footer;