import React, {useEffect, useState} from 'react';

import { Navigation, Pagination, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// import slideOne from "./img/slideOne.jpeg"
import slideTwo from "./img/slideTwo.jpg"
import slideThree from "./img/slideThree.jpeg"
// import slideFour from "./img/slideFour.jpg"
// import slideFive from "./img/slideFive.JPG"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import "./about.css"

import bcv from "./video/background.mp4"

import {useTranslation} from "react-i18next";



function About() {



    const [width, setWidth] = useState(0)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setWidth])

    const { t } = useTranslation();

    return (
        <div className="about">
            {width > 1100 &&  <video loop muted autoPlay
                    style={{
                        top: "0",
                        right: "0",
                        position: "absolute",
                        width: "100vw",
                        // height: "100%",
                        objectFit: "cover"
                    }}
            >
                <source src={bcv} type="video/mp4"/>
            </video>}
            <div className="about-info">
                <div className="about-image-wrapper">
                    {/*<Swiper*/}
                    {/*    modules={[Navigation, Pagination, A11y]}*/}
                    {/*    slidesPerView={1}*/}
                    {/*    loop={true}*/}
                    {/*    navigation*/}
                    {/*    pagination={{ clickable: true }}*/}
                    {/*    onSwiper={(swiper) => console.log(swiper)}*/}
                    {/*    onSlideChange={() => console.log('slide change')}*/}
                    {/*>*/}
                    {/*    /!*<SwiperSlide><img src={slideOne} alt="slideOne"/> </SwiperSlide>*!/*/}
                    {/*    <SwiperSlide></SwiperSlide>*/}
                    {/*    <SwiperSlide><img src={slideThree} alt="slideThree"/></SwiperSlide>*/}
                    {/*    /!*<SwiperSlide><img src={slideFour} alt="slideFour"/></SwiperSlide>*!/*/}
                    {/*    /!*<SwiperSlide><img src={slideFive} alt="slideFive"/></SwiperSlide>*!/*/}
                    {/*</Swiper>*/}
                    <img src={slideTwo} alt="slideTwo"/>
                </div>
                <div className="about-us-wrapper">
                    <div className="about-us-heading">
                        <h1> {t("about-heading")}</h1>
                    </div>
                    <div className="about-us-description">
                        <p>
                            {t("about-description-fsrParagraph")}
                        </p>
                        <p className="second-paragraph">
                            {t("about-description-scndParagraph")}
                        </p>
                        {/*<p className="list">{t("about-description-list-itemOne")}</p>*/}
                        {/*<p className="list">{t("about-description-list-itemTwo")}</p>*/}
                        {/*<p className="list">{t("about-description-list-itemThree")}</p>*/}
                        {/*<Link className="link" to="donate" spy={true} smooth={true} offset={0} duration={500}>*/}
                        {/*    {t("about-cta")}*/}
                        {/*</Link>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;