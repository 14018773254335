import dataDonate from "./dataDonate";

import "./donate.css"
import {useTranslation} from "react-i18next";

import {NavLink} from "react-router-dom";
import DonateCred from "../donateCred/DonateCred";
import bcv from "./video/video.mp4";
import React, {useEffect, useState} from "react";

import qr from "./img/qr.jpeg"


function Donate() {
    const { t } = useTranslation();



    // const [width, setWidth] = useState(0)
    //
    // useEffect(() => {
    //     function handleResize() {
    //         setWidth(window.innerWidth)
    //     }
    //
    //     window.addEventListener("resize", handleResize)
    //
    //     handleResize()
    //
    //     return () => {
    //         window.removeEventListener("resize", handleResize)
    //     }
    // }, [setWidth])


    return (
        <div className="donate" id="donate">
            {/*{width > 1100 &&  <video loop muted autoPlay*/}
            {/*                         style={{*/}
            {/*                             top: "100px",*/}
            {/*                             right: "0",*/}
            {/*                             position: "absolute",*/}
            {/*                             width: "100vw",*/}
            {/*                             height: "100%",*/}
            {/*                             objectFit: "cover"*/}
            {/*                         }}*/}
            {/*>*/}
            {/*    <source src={bcv} type="video/mp4"/>*/}
            {/*</video>}*/}
            <div className="donate-block-wrapper">
                <h1>{t("donate-title")}</h1>
            </div>
            <div className="donate-wrapper">
                            <div className="donate-block">
                                <div className="donate-block-heading">
                                    <h1>{t("donate-qr")}</h1>
                                    <div className="donate-price">{t("donate-priceL")}</div>
                                </div>
                                <div   className="donate-block-main">
                                    <div className="link-donate-wrapper">
                                        <p>Відскануйте або натисніть на QR код.</p>
                                        <a href="https://pay.concord.ua/p47Nc2VFKB" target="_blank">
                                            <img src={qr} alt="qr"/>
                                        </a>
                                        <p>Оплата здійснюється на захищеній банком сторінці</p>
                                    </div>




                                    <div className="donate-block-after"></div>
                                </div>
                            </div>
                <div className="donate-block">
                    <div className="donate-block-heading">
                        <h1>{t("donate-card")}</h1>
                        <div className="donate-price">{t("donate-priceL")}</div>
                    </div>
                    <div   className="donate-block-main">
                        {/*<p>*/}
                        {/*    {t(data.description)}*/}
                        {/*</p>*/}
                        <DonateCred/>
                        {/*<NavLink className="pay-btn" to={data.link} target="_blank">*/}
                        {/*    {t(data.donate)}*/}
                        {/*</NavLink>*/}
                        <div className="donate-block-after"></div>
                    </div>
                </div>
            </div>

        </div>
    );
}



export default Donate;

