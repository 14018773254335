import "./navigation.css"
import {Link} from "react-scroll"



import logoWhite from "./imgNavigation/charityWhite.png"
import logoBlack from './imgNavigation/charityBlack.png'
import Button from "./button/Button";
import {useState, useEffect} from "react";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'



const languages = [
    {
        code: 'ua',
        name: 'Ukrainian',
        country_code: 'ua',
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'en',
    }
]






export default function Navigation(){



    const [navBar, setNavBar] = useState(false)


    const [width, setWidth] = useState(0)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setWidth])

    const [attribute, setAttribute] = useState(false)

    function handleChange(){
        setAttribute(prevState => !prevState)
    }



    const changeBackground = () => {
        if(window.scrollY >= 5){
            setNavBar(true)
        }else{
            setNavBar(false)
        }
    }

    window.addEventListener('scroll', changeBackground)



    // const currentLanguageCode = cookies.get('i18next') || 'ua'

    const currentLanguageCode = 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
    }, [currentLanguage, t])




    // const { t } = useTranslation();

    const [language, setLanguage] = useState(true)

    const changeLng = () => {
        setLanguage(prevState => !prevState)
    }

    useEffect(() => {
        if(language === false){
            i18next.changeLanguage("en")
        }else{
            i18next.changeLanguage("ua")
        }
    }, [language])




    const styleButton = {
        color: navBar ? "black" : "white",
    }



    return(
        <div className={navBar ? "navigation active" : "navigation"}>
            <Link  to="home" spy={true} smooth={true} offset={0} duration={500}>
                <div className="navigation-logo">
                    <img src={navBar ? logoBlack : logoWhite} alt="logo" />
                </div>
            </Link>
            <div className="buttons">
                {/*<button style={styleButton} className="lang-button" onClick={changeLng} >{language ? "En" : "Ua"}</button>*/}
                <div className="hamburger-button">
                    <Button
                        navBar={navBar}
                        attribute={attribute}
                        handleChange={() => handleChange()}
                    />
                </div>
            </div>

            {
                (width > 560 || attribute)
                &&
                <div className="hamburger-menu">
                    <ul style={styleButton} className="menu-list">
                        <li  className="link">
                            <Link activeClass="active"  to="home" spy={true} smooth={true} offset={0} duration={500}>
                                {t("nav_home")}
                            </Link>
                        </li>
                        <li className="link">
                            <Link activeClass="active" to="about" spy={true} smooth={true} offset={0} duration={500} >
                                {t("nav_about")}
                            </Link>
                        </li>
                        <li className="link">
                            <Link activeClass="active"  to="donate" spy={true} smooth={true} offset={50} duration={500} >
                                {t("nav_donate")}
                            </Link>
                        </li>
                        <li className="link">
                            <Link activeClass="active"  to="contact" spy={true} smooth={true} offset={50} duration={500} >
                                {t("nav_contact")}
                            </Link>
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}