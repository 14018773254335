import React, {useEffect, useRef, useState} from 'react';

import emailjs from '@emailjs/browser';

import pdfOne from "./documents/Політика конфіденційності.pdf"
import pdfTwo from "./documents/Публічний договір (оферта).pdf"

import "./contact.css"
import {useTranslation} from "react-i18next";
import bcv from "../Footer/video/footerBg.mp4";

function Contact() {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_tupuhqs', 'template_jq2fwso', form.current, 'QYBvem-F9idJPLwnr')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }

    const { t } = useTranslation();


    const [width, setWidth] = useState(0)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setWidth])


    return (
        <div className="contact" id="contact">
            {width > 1100 &&  <video loop muted autoPlay
                                     style={{
                                         top: "0",
                                         right: "0",
                                         position: "absolute",
                                         width: "100vw",
                                         height: "100%",
                                         objectFit: "cover"
                                     }}
            >
                <source src={bcv} type="video/mp4"/>
            </video>}
            <div className="contact-info">
                <div className="contact-wrapper-heading">
                    <h1>{t("contact-title")}</h1>
                </div>
                <div className="info">
                    <h3 className="info-about-heading-name">{t("contact-about")}</h3>
                    <span className="info-about-heading-text">{t("about-description-fsrParagraph")}</span>
                    <h3 className="info-about-heading-address">{t("contact-address")}</h3>
                    <span className="info-about-heading-address-text">{t("contact-address-text")}</span>
                    <h3 className="info-about-heading-mail">{t("donate-mail")}</h3>
                    <a href = "mailto: hquarters22@gmail.com">{t("donate-mail-text")}</a>
                    <h3 className="info-about-heading-phone">{t("donate-phone")}</h3>
                    <a href="tel:+380634968600">{t("donate-phone-text")}</a>
                    <h3>{t("donate-documents")}</h3>
                    <a className="underline" href={pdfOne} target="_blank">{t("donate-policy")}</a>
                    <a className="underline" href={pdfTwo} target="_blank">{t("donate-offer")}</a>
                </div>
            </div>
            <div className="contact-forms">
                <form className="forms" ref={form} onSubmit={sendEmail}>
                    <input type="text" placeholder={t("placeholder-name")} name="user_name" required/>
                    <input type="email" placeholder={t("placeholder-mail")} name="user_mail" required/>
                    <input type="tel" placeholder={t("placeholder-phone")} name="user_phone" required/>
                    <input type="text" placeholder={t("placeholder-subject")} name="user_subject"/>
                    <button type="submit">{t("placeholder-button")}</button>
                </form>

            </div>
        </div>
    );
}

export default Contact;